<template>
    <v-container fluid class="mb-5">
        <v-row align="center" justify="center">
            <v-expansion-panels v-model="panel" multiple>
                <v-expansion-panel class="rounded-lg">
                    <v-expansion-panel-header class="font-weight-bold text-h6 py-0">
                        <v-row justify="start">
                            <v-col cols="12" md="2" align="start">
                                <v-icon color="orange">mdi-basketball</v-icon>
                                Φίλτρα
                            </v-col>
                        </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="elevation-1 rounded-lg">
                        <v-row justify="center">
                            <v-col cols="12" md="3" align="center">
                                <v-select
                                    v-model="season"
                                    color="orange"
                                    item-color="orange"
                                    label="Σεζόν"
                                    :items="seasons"
                                    item-text="name"
                                    return-object
                                />
                            </v-col>
                            <v-col v-if="!$vuetify.breakpoint.mobile" cols="1">
                                <v-divider
                                    vertical
                                    color="orange"
                                    class="custom-divider"
                                />
                            </v-col>
                            <v-col cols="12" md="3" align="center">
                                <v-select
                                    v-model="phase"
                                    label="Φάση"
                                    color="orange"
                                    item-color="orange"
                                    :items="[{title:'Προκριματικά', value:'placements'}, {title:'Regular Season', value:'regular_season'}, {title:'PlayOffs', value:'playoffs'}]"
                                    item-text="title"
                                    item-value="value"
                                    @change="league ? getRankingByLeague() : null"
                                />
                            </v-col>
                            <v-col v-if="!$vuetify.breakpoint.mobile" cols="1">
                                <v-divider
                                    vertical
                                    color="orange"
                                    class="custom-divider"
                                />
                            </v-col>
                            <v-col cols="12" md="3" align="center">
                                <v-select
                                    v-model="league"
                                    color="orange"
                                    item-color="orange"
                                    :items="cup ? leagues.concat('Cup') : leagues"
                                    label="League"
                                    item-text="title"
                                    return-object
                                    item-value="id"
                                    clearable
                                />
                            </v-col>
                            <v-col v-if="$route.name === 'Schedule'" cols="12" md="3" align="center">
                                <v-text-field
                                    v-model="round"
                                    :disabled="!league"
                                    class="pt-10"
                                    color="orange"
                                    item-color="orange"
                                    label="Αγωνιστική"
                                    persistent-hint
                                    hint="Πληκτρολόγησε τον αριθμό της αγωνιστικής που επιθυμείς"
                                    type="Number"
                                    clearable
                                />
                            </v-col>
                            <v-col v-if="!$vuetify.breakpoint.mobile && $route.name === 'Schedule'" cols="1">
                                <v-divider
                                    vertical
                                    color="orange"
                                    class="custom-divider"
                                />
                            </v-col>
                            <v-col cols="12" md="3" align="center">
                                <v-select
                                    v-if="$route.name === 'Schedule'"
                                    v-model="arena"
                                    :full-width="true"
                                    color="orange"
                                    item-color="orange"
                                    label="Γήπεδο"
                                    :items="arenas"
                                    item-text="name"
                                    item-value="id"
                                    return-object
                                    clearable
                                />
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-row>
    </v-container>
</template>

<script>
    import seasonService from '@/services/season.js'
    import leagueService from '@/services/league.js'
    import arenaService from '@/services/arena'
    import moment from 'moment'

    export default {
        props: {
            cup: {
                type: Boolean,
                deafult: true
            }
        },
        data() {
            return {
                panel: [0],
                season: null,
                league: null,
                arena: null,
                seasons: [],
                leagues: [],
                teams: [],
                filter: {},
                arenas: [],
                round: null,
                phase: 'regular_season'
            }
        },
        watch: {
            '$route'(to, from) {
                if (to !== from) {
                    this.init()
                }
            },
            season: {
                handler(value) {
                    if (value) {
                        this.$store.dispatch('setLeagueFilter', null)
                        this.filter = null
                        this.fetchAllLeagues()
                    }
                    this.getByFilters()
                }
            },
            league: {
                handler(value) {
                    if (value) {
                        this.$store.dispatch('setLeagueFilter', value)
                        this.filter = null
                    }
                    this.getByFilters()
                }
            },
            arena: {
                handler(value) {
                    if (value) {
                        this.$store.dispatch('setLeagueFilter', null)
                        this.filter = null
                    }

                    this.getByFilters()
                }
            },
            round: {
                handler(value) {
                    if (value) {
                        this.$store.dispatch('setLeagueFilter', null)
                        this.filter = null
                    }
                    this.getByFilters()
                }
            }
        },
        created() {
            this.init()
        },
        methods: {
            init() {
                this.fetchAllSeasons()
                this.fetchAllArenas()
                if (this.$store.getters.getLeagueFilter) {
                    this.league = this.$store.getters.getLeagueFilter
                    if (this.$route.name === 'Standings') {
                        this.getRankingByLeague()
                    }
                }
            },
            getRankingByLeague() {
                if (this.league === 'Cup') {
                    this.$emit('getCupState', true)
                } else if (this.phase === 'playoffs') {
                    this.$emit('getCupState', false, this.league.id, this.season.id)
                } else {
                    if (this.$route.name === 'Standings') {
                        this.$emit('getRankingByLeague', this.league.id, this.season.id, this.phase)
                    } else if (this.$route.name === 'Schedule') {
                        this.$emit('getScheduleByLeague', this.round, this.arena ? this.arena.id : null, this.league === 'Cup', this.league ? this.league.id : null, this.season ? this.season.id : null, this.phase ? this.phase : null)
                    }
                }
            },
            getByFilters() {
                if (this.$route.name === 'Standings') {
                    this.getRankingByLeague()
                } else {
                    this.$emit('getByFilters', this.round, this.arena ? this.arena.id : null, this.league === 'Cup', this.league ? this.league.id : null, this.season ? this.season.id : null, this.phase ? this.phase : null)
                }
            },
            fetchAllSeasons() {
                seasonService.getSeasons(1, 100).then(resp => {
                    this.seasons = resp.data.content
                    this.season = resp.data.content.filter(element => moment().isBetween(moment(element.startDate), moment(element.endDate)))[0]
                })
            },
            fetchAllLeagues() {
                leagueService.getLeagues(1, 100, this.season, this.phase).then(resp => {
                    this.leagues = resp.data
                    this.league = this.$store.getters.getLeagueFilter ? this.$store.getters.getLeagueFilter : resp.data[0]
                    if (this.$route.name === 'Standings') {
                        this.getRankingByLeague()
                    } else {
                        this.getByFilters()
                    }
                })
            },
            fetchAllArenas() {
                arenaService.getArenas(1, 100).then(resp => {
                    this.arenas = resp.data.content
                })
            }
        }
    }
</script>

<style>
.v-select__selection--comma {
    font-weight: 600 !important;
}

.v-select {
    font-family: Ubuntu, Arial, sans-serif !important;
    font-weight: 600;
}

.v-text-field {
    font-family: Ubuntu, Arial, sans-serif !important;
    font-weight: 600;
}

.v-list-item {
    font-family: Ubuntu, Arial, sans-serif !important;
    font-weight: 600;
}

.v-list-item__title {
    white-space: normal !important;
}
</style>

<style scoped>
.text-h6 {
    font-size: 1.25rem !important;
    letter-spacing: 0.0125em !important;
    line-height: 2rem;
    font-family: Mansalva, Arial, sans-serif !important;
}

.custom-divider {
    border-width: 1px;
}

</style>
